import React, { useEffect, useRef } from 'react'
import { Button, Header } from '../../components'
import { GiCancel } from 'react-icons/gi'
import { FaPrint } from 'react-icons/fa'
import Icon from '../../data/icon.png'
import ReactToPrint from 'react-to-print'
import Barcode from 'react-barcode';
const PrintColis = ({ colis, onClose }) => {
    const componentRef = useRef();

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col w-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10  rounded-xl'>
                        <div className=''>
                            <Header title="Reçu" />
                        </div>
                        <div ref={componentRef} className="flex flex-col p-4">
                            <div className="flex justify-between gap-3">
                                <div className="flex gap-1">
                                    <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
                                    <div className="flex flex-col gap-1">
                                        <h1>KHOUNE TRANSPORT</h1>
                                        <h1> سفــــــــريات خــــــــونــــه للنقل </h1>
                                        <h2>Tel: 44426363 - 21060708 :الهاتف</h2>
                                    </div>
                                </div>
                                <Barcode height={30} value={colis.code} />
                            </div>
                            <div>
                                <div className='flex justify-center items-center'>
                                    <h1 className='text-lg '>Reçu</h1>
                                </div>
                                <div className='flex justify-between '>
                                    <h2>Agence : {colis.agenceDepart.nom}</h2>
                                    <h2>Date ticket: {colis.created_at} </h2>
                                </div>
                                <table>
                                    <thead>
                                        <tr>

                                            <th>Tel Expediteur</th>
                                            <th>Destination</th>
                                            <th>Tel Receveur</th>
                                            <th>Date Dep</th>
                                            <th>Ordre Bus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{colis.telExpediteur}</td>
                                            <td>{colis.agenceDestination.nom}</td>
                                            <td>{colis.telReceveur}</td>
                                            <td>{colis.dateDepart}</td>
                                            <td>{colis.ordreBus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Code </th>
                                            <th>Poid Bagage</th>
                                            <th>Type</th>
                                            <th>Montant Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{colis.code}</td>
                                            <td>{colis.poid}</td>
                                            <td>{colis.type}</td>
                                            <td>{colis.montant}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                <ReactToPrint
                                    trigger={() => {
                                        return <Button icon={<FaPrint />} color="ml-1 bg-green-500" textColor="text-white" text="Imprimer" />
                                    }}
                                    content={() => componentRef.current}
                                    documentTitle='Recipiet'
                                    pageStyle='print'
                                />
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrintColis