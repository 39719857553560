import React, {createContext,useContext,useState} from 'react';

const StateContext=createContext();

const initialState={
    chat:false,
    cart:false,
    userProfile:false,
    notification:false,
    user:null,
    token:null,
    fireToken:null,
    setUser:()=>{},
    setToken:()=>{},
    setFireToken:()=>{}
}


export const ContextProvider=({children})=>{
    const [activeMenu,setActiveMenu]=useState(true);
    const [isClicked,setIsClicked]=useState(initialState);
    const [userClose,setUserClose]=useState(false);
    const [screenSize,setScreenSize]=useState(undefined);
    const [currentColor, setCurrentColor]=useState('rgb(59 130 246)');
    const [currentMode,setCurrentMode]=useState('Light');
    const [themeSettings,setThemeSettings]=useState(false);
    const [user,setUser]=useState({});
    const[token,_setToken]=useState(localStorage.getItem('ACCESS_TOKEN'));
    const [fireToken,setFireToken]=useState(null);


    const setMode=(mode)=>{
        setCurrentMode(mode);
        localStorage.setItem('themeMode',mode);
    }
    const setToken=(token)=>{
        _setToken(token)
        if(token){
            localStorage.setItem('ACCESS_TOKEN',token);
        }else{
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }

    const setColor=(e)=>{
        setCurrentColor(e.target.value);
        localStorage.setItem('colorMode',e.target.value);
    }
    const handleClick=(clicked)=>{
        setIsClicked({
            ...initialState,[clicked]:true
        });
    }
    return (
        <StateContext.Provider value={{ 
            activeMenu, setActiveMenu,
            isClicked,setIsClicked,
            handleClick,
            screenSize,setScreenSize,
            currentColor,setCurrentColor,
            currentMode, setCurrentMode,
            setMode,setColor,
            themeSettings,setThemeSettings,
            user,
            token,
            setUser,
            setToken,
            userClose,
            setUserClose,
            fireToken,
            setFireToken,
            
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext=()=>useContext(StateContext);

